<template>
    <h1 
        v-if="node.nodeType == 'heading-1'"
        v-html="node.content[0].value"
    />
    <h2 
        v-else-if="node.nodeType == 'heading-2'"
        v-html="node.content[0].value"
    />
    <h3 
        v-else-if="node.nodeType == 'heading-3'"
        v-html="node.content[0].value"
    />
    <p 
        v-else
        v-html="node.content[0].value"
    />
</template>

<script>
    export default {
        props: [
            'node',
        ],
    }
</script>
